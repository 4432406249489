import placeholderImage from '../assets/project.jpg'; // Placeholder image
import Groupy from '../data/projects/Groupy.js'; // Import the Groupy object
import RheBoot from '../data/projects/RheBoot.js'; // Import the RheBoot object
import Articulate from '../data/projects/Articulate.js'; // Import the Articulate object
import Bidly from '../data/projects/Bidly.js'; // Import the Bidly object

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../assets/projects', true, /\.(png|jpe?g|svg)$/));

// Log the imported images to debug
//console.log('Imported images:', images);

// Add more project data here...
const projectData = [ Groupy, RheBoot, Articulate, Bidly ];

// Apply the placeholder image to any project that does not have an image property defined or has an invalid image path
projectData.forEach(project => {
  console.log('Processing project:', project);
  if (!project.image || !images[project.image]) {
    project.image = placeholderImage;
  } else {
    project.image = images[project.image];
  }
  project.additionalImages = project.additionalImages.map(image => {
    return images[image] ? images[image] : placeholderImage;
  });
});

export default projectData;
