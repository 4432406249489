import React from 'react';
import { Link } from 'react-router-dom';
import projectData from './projectData'; // Import project data

const Projects = () => {
  return (
    <section id="projects" className="py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-6">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {projectData.map((project) => (
            <div key={project.id} className="bg-white p-6 rounded-lg shadow-lg">
              <Link to={`/projects/${project.id}`}>
                <img src={project.image} alt={project.title} className="w-full h-40 object-cover rounded-md mb-4"/>
                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                <p className="text-gray-700">{project.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
