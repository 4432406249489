const Articulate = {
	id: 3,
	title: 'Articulate',
	description: 'AI Generated Article Summaries',
	longDescription: `	Articulate is a simple website that displays AI generated summaries of articles. It uses scripts to automatically update articles, sitemaps, and view counts. All information is stored using XML data files.
	
	Generation of articles uses a custom GPT which adheres to a standard XML format. Multiple AI web searches are done to gather information, fact check, and sources.`,
	image: 'articulate/Articulate.png',
	additionalImages: [
		'articulate/Home p1.png', 
		'articulate/Home p2.png', 
		'articulate/Article p1.png', 
		'articulate/Article p2.png', 
		'articulate/Article p3.png'
	],
	linkurl: 'https://articulate.rob-hob.com'
}

export default Articulate;
