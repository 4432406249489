import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-6">About Me</h2>
        <p className="text-gray-700">
		  I am a seasoned IT professional with a degree in Computer & Information Technologies. My skill set encompasses developing dynamic web applications and automation scripts across multiple languages. Proficient in PHP, Python, HTML, CSS, JavaScript, and MySQL database design, I have successfully executed a variety of projects, including inventory management systems, interactive games, streaming platforms, e-commerce storefronts, and custom WordPress solutions.
		</p>
		<br />
		<p className="text-gray-700">
		  Additionally, my background includes extensive experience with both Windows and Linux systems, providing comprehensive IT support services and systems administration. I have managed web hosting both with service providers and locally using Apache. Furthermore, I have managed e-commerce shops on platforms such as Shopify, Amazon, and TikTok Shop, leveraging Meta and Google Ads for effective digital marketing campaigns.
		</p>
		<br />
		<p className="text-gray-700">
		  My diverse portfolio and passion for innovation drive my commitment to delivering high-quality, scalable solutions. With a comprehensive skill set and dedication to staying updated with the latest industry trends, I am well-equipped to tackle any challenge and contribute effectively to any team and project.
		</p>
      </div>
    </section>
  );
};

export default About;
