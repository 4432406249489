const Groupy = {
  id: 1,
  title: 'Grou.py',
  description: 'Grou.py does exactly what it sounds like. It is an application written in Python that creates groups of given images given a list of URLs.',
  longDescription: `Grou.py is a python application that accepts a CSV or Excel file with the columns "WorkOrder ID" and "Production Artwork." It allows users to specify the job name and size, with default values of "Turn" and "10," respectively. The program will download the URLs contained in the "Production Artwork" column.

Upon execution, Grou.py will create a "Turn" folder and a "Printed" folder. For every 10 images (or the specified group size), a "Group" folder will be created containing the images and a new CSV file with the columns "WorkOrder ID," "Production Artwork," and "Status" for each image in that group folder.

For example, if a group size of 10 is selected and 24 URLs are provided, the following folders and files will be created:
Turn
|__ Group_1
    |__ Images 1-10
    |__ group_data.csv
|__ Group_2
    |__ Images 11-20
    |__ group_data.csv
|__ Group_3
    |__ Images 21-24
    |__ group_data.csv
Printed
|__ Empty, intended for moving completed work groups into.

Q: What happens when an image doesn't exist?
A: When a URL is down or does not exist, the row will turn red for easy selection. Grou.py will continue until every URL has been processed. The last 3 example images below show error examples.

Q: What do the different highlighting colors indicate?
A: Blue = Downloading/Processing, Green = Completed Downloading/Processing, Red = No image found at the provided URL.`,
  image: 'groupy/groupy-logo.png',
  additionalImages: [
    'groupy/Complete/DownloadingImagesinProgress.png',
    'groupy/Complete/DownloadingImagesComplete.png',
    'groupy/Complete/TurnCreated.png',
    'groupy/Complete/GroupFiles.png',
    'groupy/Complete/OriginalCSVandGroupedCSV.png',
    'groupy/Complete/CopyOverview.png',
    'groupy/Error/Inprogresserror.png',
    'groupy/Error/CompletedError.png',
    'groupy/Error/OverallError.png'
  ],
  linkurl: null
};

export default Groupy;
