import React, { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import ProjectDetails from './components/ProjectDetails';
import Contact from './components/Contact';
import Footer from './components/Footer';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [pathname, hash]);

  return null;
};

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function Home() {
  return (
    <>
      <Hero />
      <About />
      <Projects />
      <Services />
      <Contact />
    </>
  );
}

function App() {
  return (
	
	<Router>
		<Wrapper>
		  <div className="flex flex-col min-h-screen">
			<NavBar />
			<main className="flex-grow">
			  <ScrollToTop />
			  <Routes>
				<Route path="/" element={<Home />} />
				<Route path="/projects/:id" element={<ProjectDetails />} />
			  </Routes>
			</main>
			<Footer />
		  </div>
		</Wrapper>
	</Router>
	
  );
}

export default App;