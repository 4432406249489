import React from 'react';

const Services = () => {
  return (
    <section id="services" className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-6">Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Web Development</h3>
            <p className="text-gray-700">Building tailored web applications...</p>
          </div>
		  <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">E-commerce Solutions</h3>
            <p className="text-gray-700">Developing and managing e-commerce storefronts on platforms...</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">UI/UX Design</h3>
            <p className="text-gray-700">Designing user-friendly interfaces...</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">SEO Optimization</h3>
            <p className="text-gray-700">Improving website visibility...</p>
          </div>
		  <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Digital Marketing Campaigns</h3>
            <p className="text-gray-700">Planning and executing digital marketing strategies...</p>
          </div>
		  <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Consultation</h3>
            <p className="text-gray-700">IT, Web, and E-commerce consultations...</p>
          </div>
		  <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">IT Support</h3>
            <p className="text-gray-700">Providing comprehensive support for both Windows and Linux systems...</p>
          </div>
		  <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Systems Administration</h3>
            <p className="text-gray-700">Managing and maintaining server infrastructure...</p>
          </div>
		  <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">Automation</h3>
            <p className="text-gray-700">Creating scripts to automate repetitive tasks and improve efficiency...</p>
          </div>		  
        </div>
      </div>
    </section>
  );
};

export default Services;
