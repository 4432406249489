import React from 'react';

const NavBar = () => {
  return (
    <nav className="bg-gray-800 p-4 text-white fixed w-full z-10">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        {/* Title container */}
        <div className="text-xl mb-4 md:mb-0 md:text-2xl flex-grow text-center md:text-left whitespace-nowrap">
          Rob-Hob
        </div>
        {/* Navigation items */}
        <div className="flex flex-wrap justify-center md:justify-end mb-4 md:mb-0">
            <a href="/#home" className="hover:underline mx-2">Home</a>
            <a href="/#about" className="hover:underline mx-2">About</a>
            <a href="/#projects" className="hover:underline mx-2">Projects</a>
			<a href="/#services" className="hover:underline mx-2">Services</a>
            <a href="/#contact" className="hover:underline mx-2">Contact</a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
