import React, { useState, useEffect } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [mailtoLink, setMailtoLink] = useState('');

  useEffect(() => {
    // Encode email details to obfuscate them from scrapers
    const user = 'contact';
    const domain = 'rob-hob.com';
    setMailtoLink(`mailto:${user}@${domain}`);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLinkWithDetails = `${mailtoLink}?subject=Inquiry Message&body=Name:%20${encodeURIComponent(name)}%0AEmail:%20${encodeURIComponent(email)}%0AMessage:%20${encodeURIComponent(message)}`;

    window.location.href = mailtoLinkWithDetails;

    // Optional: Clear form fields
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <section id="contact" className="py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-6">Contact</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-xl font-bold mb-4">Get in Touch</h3>
            <p className="text-gray-700 mb-6">Feel free to reach out for any inquiries...</p>
            <p className="text-gray-700 mb-2">
              <strong>Email: </strong> 
              <a href={mailtoLink}>contact@rob-hob.com</a>
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="w-full p-2 border rounded"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Message</label>
                <textarea
                  className="w-full p-2 border rounded h-32"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button type="submit" className="bg-blue-500 text-white p-2 rounded">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
