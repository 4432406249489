import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import projectData from './projectData';

const ProjectDetails = () => {
  const { id } = useParams();
  const project = projectData.find(p => p.id === parseInt(id));
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [direction, setDirection] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [nextIndex, setNextIndex] = useState(null);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

  }, [id]);
  
  useEffect(() => {
    if (isAnimating) {
      const timeout = setTimeout(() => {
        setPhotoIndex(nextIndex);
        setNextIndex(null);
        setDirection('');
        setIsAnimating(false);
      }, 500); // Match this to your animation duration
      return () => clearTimeout(timeout);
    }
  }, [isAnimating, nextIndex]);

  if (!project) {
    return <div>Project not found</div>;
  }

  const handleOpen = (index) => {
    setPhotoIndex(index);
    setOpen(true);
  };

  const handlePrev = () => {
    if (isAnimating) return;
    const newIndex = (photoIndex - 1 + project.additionalImages.length) % project.additionalImages.length;
    setIsAnimating(true);
    setDirection('right');
    setNextIndex(newIndex);
  };

  const handleNext = () => {
    if (isAnimating) return;
    const newIndex = (photoIndex + 1) % project.additionalImages.length;
    setIsAnimating(true);
    setDirection('left');
    setNextIndex(newIndex);
  };
  
	const extractFilename = (filename, section) => {
	  const parts = filename.split('.');
	  return parts[section] || null;
	};
	
 return (
	  <div className="container mx-auto py-20" style={{ padding: '10rem 10px 0 10px' }}>
		<div className="flex flex-col md:flex-row items-start mb-6">
		  <img src={project.image} alt={project.title} className="w-full md:w-full h-auto object-cover rounded-md mb-6 md:mb-0 md:mr-6" />
		  <div>
			<h2 className="text-3xl font-bold mb-4">{project.title}</h2>
			<pre className="text-gray-700 whitespace-pre-wrap">{project.longDescription}</pre>
			{project.linkurl && (
			  <div className="mt-4 text-center">
				<a
				  href={project.linkurl}
				  target="_blank"
				  rel="noopener noreferrer"
				  className="inline-block bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
				>
				  View Now
				</a>
			  </div>
			)}
		  </div>
		</div>
		<div>
		  <h3 className="text-2xl font-bold mb-4">Screenshots</h3>
		  <div className="carousel-wrapper-outer">
			<button onClick={handlePrev} className="carousel-button left">◀</button>
			<div className="carousel-container">
			  <div className="carousel-wrapper">
				{project.additionalImages.map((image, index) => {
				  let className = 'carousel-image';
				  if (index === photoIndex) {
					className += ` current ${direction}`;
				  } else if (index === nextIndex) {
					className += ` next ${direction}`;
				  }

				  return (
					<div key={index} className="carousel-item">
					  <img
						src={image}
						alt={`${project.title} ${index + 1}`}
						className={className}
						style={{
						  zIndex: index === photoIndex || index === nextIndex ? 1 : 0,
						  opacity: index === photoIndex || index === nextIndex ? 1 : 0,
						}}
						onClick={() => handleOpen(index)}
					  />
					</div>
				  );
				})}
			  </div>
			  {project.additionalImages.length > 0 && (
				<div className="filename-container">
				  {project.additionalImages.map((image, index) => {
					let className = 'image-filename';
					if (index === photoIndex) {
					  className += ` current ${direction}`;
					} else if (index === nextIndex) {
					  className += ` next ${direction}`;
					}

					return (
					  <div
						key={index}
						className={className}
						style={{
						  zIndex: index === photoIndex || index === nextIndex ? 1 : 0,
						  opacity: index === photoIndex || index === nextIndex ? 1 : 0,
						}}
					  >
						{extractFilename(image.split('/').pop(), 0)}
					  </div>
					);
				  })}
				</div>
			  )}
			</div>
			<button onClick={handleNext} className="carousel-button right">▶</button>
		  </div>
		</div>
		{open && (
		  <Lightbox
			open={open}
			close={() => setOpen(false)}
			slides={project.additionalImages.map((image) => ({ src: image }))}
			index={photoIndex}
			onIndexChange={setPhotoIndex}
		  />
		)}
	  </div>
	);
};

export default ProjectDetails;