import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 p-4 text-white">
      <div className="container mx-auto">
        {/* Large screens: flex-row, small screens: flex-col */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Navigation links */}
          <div className="flex flex-wrap justify-center md:justify-end mb-4 md:mb-0">
            <a href="/#home" className="hover:underline mx-2">Home</a>
            <a href="/#about" className="hover:underline mx-2">About</a>
            <a href="/#projects" className="hover:underline mx-2">Projects</a>
			<a href="/#services" className="hover:underline mx-2">Services</a>
            <a href="/#contact" className="hover:underline mx-2">Contact</a>
          </div>
          {/* Copyright text */}
          <div className="text-center md:text-left w-full md:w-auto">
            &copy; 2023 Rob-Hob
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
