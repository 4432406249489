const Bidly = {
	id: 4, 
	title: 'Bidly', 
	description: 'Storefront & Inventory Manager', 
	longDescription: `	Bidly is a platform that allows users to manage stores and their inventories. Once a store is created, users can add multiple inventories. A public store page is then generated, showcasing each of the store's inventories. When a visitor adds items to their cart, an order summary is created and stored in a database`, 
	image: 'bidly/Bidly-Logo.png', 
	additionalImages: ['bidly/Login.png', 'bidly/Register.png', 'bidly/Store View.png', 'bidly/New Store.png', 'bidly/Store Inventory.png', 'bidly/New Inventory.png', 'bidly/Breakfast Inventory.png', 'bidly/Manage Inventory Item.png', 'bidly/Storefront.png'], 
	linkurl: null
}

export default Bidly;