import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Particles } from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import preload from '../assets/hero/Sky-preload.jpg';
import sky from '../assets/hero/Sky.png';
import mountainSm from '../assets/hero/Mountain-sm.png';

const Hero = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [mountainHeight, setMountainHeight] = useState(0);
  const [mountainAnimation, setMountainAnimation] = useState(false);
  const mountainRef = useRef(null);

  const updateMountainHeight = () => {
    const mountainImage = new Image();
    mountainImage.src = mountainSm;

    mountainImage.onload = () => {
      const aspectRatio = mountainImage.naturalWidth / mountainImage.naturalHeight;
      const calculatedHeight = (window.innerWidth / aspectRatio);
      setMountainHeight(calculatedHeight);
    };
  };

  useEffect(() => {
    updateMountainHeight(); // Initial height calculation

    // Update height on resize
    window.addEventListener('resize', updateMountainHeight);

    // Clean up event listener
    return () => window.removeEventListener('resize', updateMountainHeight);
  }, []);

  useEffect(() => {
    const skyImage = new Image();
    const mountainImage = new Image();

    skyImage.src = sky;
    mountainImage.src = mountainSm;

    Promise.all([skyImage.decode(), mountainImage.decode()])
      .then(() => {
        setImagesLoaded(true);
        updateMountainHeight(); // Update height after images are loaded
        setMountainAnimation(true); // Start animation
      })
      .catch(err => console.error("Error loading images:", err));

    // Add scroll event listener for parallax effect
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (mountainRef.current) {
      const scrollPosition = window.pageYOffset;
      // Apply parallax effect
      mountainRef.current.style.transform = `translateY(${scrollPosition * 0.1}px)`;
    }
  };

  const particlesInit = useCallback(async engine => {
    await loadSlim(engine);
  }, []);

  return (
    <section id="home" className="relative bg-cover bg-center h-screen bg-gray-100 text-outline overflow-hidden">
      {/* Preload image */}
      <div 
        className="absolute inset-0 bg-cover bg-center transition-opacity duration-500"
        style={{ 
          backgroundImage: `url(${preload})`,
          opacity: imagesLoaded ? 0 : 1
        }}
      />
      
      {/* Sky image */}
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ 
          backgroundImage: `url(${sky})`,
          opacity: imagesLoaded ? 1 : 0
        }}
      />
      
      {/* Mountain image */}
      <div 
        ref={mountainRef}
        className={`absolute inset-x-0 bottom-0 bg-bottom bg-contain bg-no-repeat transition-transform duration-300 ease-out ${mountainAnimation ? 'slide-up' : ''}`}
        style={{ 
          backgroundImage: `url(${mountainSm})`,
          width: '100%',
          height: `${mountainHeight}px`,
          opacity: imagesLoaded ? 1 : 0,
        }}
      />

      <div className="container mx-auto h-full flex items-center justify-center relative z-1">
        <h1 className="text-white text-5xl font-bold">Welcome</h1>
      </div>

      {/* Particles container */}
      <div 
        className="absolute bottom-0 left-0 w-full overflow-hidden"
        style={{ height: `${mountainHeight}px` }}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: { enable: false },
            background: {
              color: {
                value: "transparent",
              },
            },
            fpsLimit: 60,
            particles: {
              color: {
                value: "#ffffff",
              },
              move: {
                direction: "bottom-left",
                enable: true,
                outModes: {
                  default: "out",
                },
                random: false,
                speed: { min: 0.01, max: 10 },
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 80,
                },
                value: 30,
              },
              opacity: {
                value: { min: 0.1, max: 0.5 },
                animation: {
                  enable: true,
                  speed: 1,
                  minimumValue: 0.1,
                },
              },
              shape: {
                type: ["circle", "edge", "triangle"],
              },
              size: {
                value: { min: 0.01, max: 3 },
                animation: {
                  enable: true,
                  speed: 1,
                  minimumValue: 1,
                },
              },
              blur: {
                value: 2,
              },
            },
            detectRetina: true,
            interactivity: {
              detectsOn: "window",
              events: {
                onHover: {
                  enable: true,
                  mode: "grab",
                },
                modes: {
                  grab: {
                    distance: 140,
                    links: {
                      opacity: 1,
                    },
                  },
                },
                onClick: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                repulse: {
                  distance: 200,
                  duration: 1,
                },
                push: {
                  quantity: 50,
                },
              },
            },
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </section>
  );
};

export default Hero;
