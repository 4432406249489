const RheBoot = {
	id: 2, 
	title: 'Rheboot', 
	description: 'Find popular movies and create a watchlist of your favorites. View trailers, descriptions, ratings, and more!', 
	longDescription: `Rheboot is a comprehensive movie library that utilizes third-party APIs to gather data on trending and personally chosen movies. Users can search for their favorite movies, view YouTube trailers, and create personalized watchlists.

The platform leverages a variety of libraries and languages to ensure seamless functionality and an appealing user experience.

-Frontend
The frontend is built using Bootstrap libraries and custom CSS to create a visually pleasing UI, all built on a foundation of HTML. JavaScript provides additional functionality and interactivity.

-Backend
The backend is primarily written in PHP to ensure secure functionality. Automation tasks are handled using a variety of scripts written in Python, Bash, and PHP.

Create a new account or use test:test to try it out yourself!`, 
	image: 'rheboot/logo.png', 
	additionalImages: ['rheboot/Home.png', 'rheboot/About.png', 'rheboot/Watchlist.png', 'rheboot/Disney Category.png', 'rheboot/Charlie Search Resullts.png', 'rheboot/Profile.png', 'rheboot/Login.png'], 
	linkurl: 'https://rheboot.rob-hob.com'
}

export default RheBoot;